import type { CategoryData } from 'service/category/model.ts';
import { CategoryItemContent } from 'components/categories/CategoryItemContent';
import noImagePlaceholder from 'icons/no-image.svg';
import { getCategoryUrl } from 'utils/url.ts';
import { getCategoryImage } from 'utils/categoryIdToImagePath';

export function CategoryLink({ category, onClick }: { category: CategoryData; onClick: () => void }) {
	const imageSrc = getCategoryImage(category.id) ?? noImagePlaceholder.src;

	return (
		<a
			class="group/category-button cursor-pointer flex flex-row md:flex-col gap-2 rounded-xl bg-neutral-3 py-2 px-4 md:p-4 items-center hover:bg-neutral-10 h-full w-full"
			href={getCategoryUrl(category)}
			onClick={onClick}
		>
			<CategoryItemContent imageSrc={imageSrc}>{category.name}</CategoryItemContent>
		</a>
	);
}
