import { type ComponentChildren } from 'preact';
import { Image } from 'components/atoms/Image.tsx';
import { cn } from 'utils/cn';

interface Props {
	children: ComponentChildren;
	imageClassName?: string;
	imageSrc: string;
}

export function CategoryItemContent({ children, imageClassName, imageSrc }: Props) {
	return (
		<>
			{/* Use the order-x to specify the actual element position. Having text first in HTML should improve SEO. */}
			<div class="text-center h-full flex items-start flex-shrink-[50] text-sm md:text-xs md:leading-4 font-medium md:text-dark-blue-50 order-2 group-hover/category-button:text-dark-blue">
				{children}
			</div>
			<Image
				alt=""
				className={cn(
					'basis-6 md:basis-[56px] order-1 mix-blend-multiply object-contain aspect-square w-6 h-6 md:w-full md:h-full',
					imageClassName,
				)}
				src={imageSrc}
			/>
		</>
	);
}
