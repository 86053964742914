import { categoriesData } from 'signals/category';
import { CategoriesModal } from 'components/modal/categories/CategoriesModal.tsx';
import { CategoryLink } from 'components/categories/CategoryLink';
import { openModal, categoryModalOpen, searchPhrase } from 'components/modal/categories/signals';
import partition from 'lodash/partition';
import { getCategoryUrl } from 'utils/url.ts';
import { SessionStorage } from 'utils/SessionStorage.ts';
import { SearchType } from 'lib/dot/enum/searchType.ts';
import { useMemo } from 'preact/hooks';
import { activeCategory } from 'signals/category';
import { useSignal } from '@preact/signals';
import type { CategoryData } from 'service/category/model';
import { AllCategoriesButton } from './AllCategoriesButton';

const DISPLAYED_HP_CATEGORY_IDS = [1, 8, 15, 27, 28];

export function CategoryList({ ssrAllCategories }: { ssrAllCategories: CategoryData[] }) {
	const isInitialized = useSignal(false);
	if (!isInitialized.peek()) {
		isInitialized.value = true;
		categoriesData.value = ssrAllCategories;
	}

	// for better SEO results, we need all categories in the HTML, but we do not show them to the users
	const [displayedCategories, hiddenCategories] = useMemo(
		() => partition(categoriesData.peek(), ({ id }) => DISPLAYED_HP_CATEGORY_IDS.includes(id)) || [[], []],
		[categoriesData.peek()],
	);

	const handleModalClose = () => {
		activeCategory.value = null;
		categoryModalOpen.value = false;
		searchPhrase.value = '';
	};

	return (
		<div class="w-full">
			<div class="mx-auto grid grid-cols-1 grid-rows-6 md:grid-cols-6 md:grid-rows-1 gap-2 md:gap-4 max-w-[848px] justify-items-center">
				{displayedCategories?.filter(Boolean).map((category) => (
					<CategoryLink
						category={category}
						key={category.id}
						onClick={() => {
							SessionStorage.setSearchMeta({ searchType: SearchType.CATEGORY });
						}}
					/>
				))}
				<AllCategoriesButton onClick={openModal} />
				{hiddenCategories?.filter(Boolean).map((category) => (
					<a key={category.id} href={getCategoryUrl(category)} className="hidden">
						{category.name}
					</a>
				))}
			</div>
			{categoryModalOpen.value && (
				<CategoriesModal
					onClose={handleModalClose}
					onClickAway={handleModalClose}
					onSelect={() => {
						SessionStorage.setSearchMeta({ searchType: SearchType.CATEGORY });
					}}
				/>
			)}
		</div>
	);
}
