import { localize } from 'i18n/localize';
import { Icon } from 'components/atoms/Icon';
import { CategoryItemContent } from 'components/categories/CategoryItemContent';

const $t = localize();

export function AllCategoriesButton({ onClick }: { onClick: () => void }) {
	return (
		<button
			aria-haspopup="true"
			class="group/category-button cursor-pointer flex flex-row md:flex-col gap-2 rounded-xl py-[10px] px-4 w-[216px] md:p-4 items-center hover:bg-neutral-10 h-full md:w-full md:bg-neutral-3"
			data-test="category-button-all"
			onClick={onClick}
		>
			<CategoryItemContent imageSrc={'/icon/imageCategories/all.svg'} imageClassName="w-5 h-5 md:w-10 md:h-10">
				<span class="flex-shrink">{$t.categories.allCategories}</span>
				<Icon type="keyboard_arrow_down" className="md:hidden ml-1" size={20} />
			</CategoryItemContent>
		</button>
	);
}
